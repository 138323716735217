import { Product } from "./product";
const product = new Product();


class Filters {
    constructor(){
        this.filterContainer = document.querySelector('#filter-container');
    }
    async truckFilter(products) {
        const truckDiv = document.createElement('div');
        truckDiv.classList.add('col-md-12', 'mb-4', 'form-control');

        const truckSelect = document.createElement('select');
        truckSelect.classList.add('form-select', 'form-select-lg', 'truck-filter','my-4');
        truckSelect.id = 'category-filter';
        truckSelect.name = 'category';
        truckSelect.required = true;
        truckDiv.innerHTML = '<label class="form-select-label fw-bold fs-4" for="category-filter">รถโม่คอนกรีต</label>';
        truckDiv.appendChild(truckSelect);

        // Append the select element to the container
        this.filterContainer?.appendChild(truckDiv);

        // Create default option
        const defaultOption = new Option('เลือกรถโม่', '');
        truckSelect.appendChild(defaultOption);

        // Create and append other category options
        (await this.getTruckFilters(products)).forEach(category => {
            const option = new Option(category.name, category.id);
            truckSelect.appendChild(option);
        });

        // document.querySelector('#category-filter')?.addEventListener('input', e => {
        //     product.applyFiltersAndDisplay(products, {category_id: parseInt(e.target.value)});
        //     concrete.applyFiltersAndDisplay(products,{category_id: parseInt(e.target.value)});
        // });
    }

    async strengthFilter(products) {
        const strengthDiv = document.createElement('div');
        strengthDiv.classList.add('col-md-12', 'mb-4', 'form-control');

        const strengthSelect = document.createElement('select');
        strengthSelect.classList.add('form-select', 'form-select-lg', 'strength-filter','my-4');
        strengthSelect.id = 'strength-filter';
        strengthSelect.name = 'strength';
        strengthSelect.required = true;

        const strengthDefaultOptions = new Option('เลือกกำลังอัด','')
        strengthSelect.appendChild(strengthDefaultOptions);
         
        (await this.getStrengthFilters(products)).forEach(strength => {
            const option = new Option(strength.name, strength.strength);
            strengthSelect.appendChild(option);
        })

        strengthDiv.innerHTML = '<label class="form-select-label fw-bold fs-4" for="strength-filter">กำลังอัด</label>';
        strengthDiv.appendChild(strengthSelect)
        this.filterContainer?.appendChild(strengthDiv);


        // document.querySelector('#strength-filter')?.addEventListener('input',e=>{
        //     product.applyFiltersAndDisplay(products,{prod_strenght: parseInt(e.target.value)});
        //     concrete.applyFiltersAndDisplay(products,{prod_strenght: parseInt(e.target.value)});
        // });

    }

    async typeFilter(products) {
        const typeDiv = document.createElement('div');
        typeDiv.classList.add('col-md-12', 'mb-4', 'form-control');

        const typeSelect = document.createElement('select');
        typeSelect.classList.add('form-select', 'form-select-lg', 'type-filter','my-4');
        typeSelect.id = 'type-filter';
        typeSelect.name = 'type';
        typeSelect.required = true;

        const typeDefaultOptions = new Option('เลือกประเภท','')
        typeSelect.appendChild(typeDefaultOptions);
        
        (await this.getTypeFilters(products)).forEach(type => {
            const option = new Option(type.concrete_type_name, type.concrete_type_id);
            typeSelect.appendChild(option);
        });

        typeDiv.innerHTML = '<label class="form-select-label fw-bold fs-4" for="type-filter">คอนกรีตผสมเสร็จ</label>';
        typeDiv.appendChild(typeSelect);
        this.filterContainer?.appendChild(typeDiv);


        // document.querySelector('#type-filter')?.addEventListener('input',e=>{
        //     product.applyFiltersAndDisplay(products,{concrete_type_id: parseInt(e.target.value)});
        //     concrete.applyFiltersAndDisplay(products,{concrete_type_id: parseInt(e.target.value)});
        // });
    }

    async placeFilter(products) {
        const placeDiv = document.createElement('div');
        placeDiv.classList.add('col-md-12', 'mb-4', 'form-control');

        const placeSelect = document.createElement('select');
        placeSelect.classList.add('form-select', 'form-select-lg', 'place-filter','my-4');
        placeSelect.id = 'place-filter';
        placeSelect.name = 'place';
        placeSelect.required = true;

        const placeDefaultOptions = new Option('เลือกพื้นที่','')
        placeSelect.appendChild(placeDefaultOptions);

        ((await this.getPlaceFilters(products)).forEach(place=>{
            const placeoption = new Option(place.PROVINCE_NAME,place.PROVINCE_CODE);
            placeSelect.appendChild(placeoption);
        }))

        placeDiv.innerHTML = '<label class="form-select-label fw-bold fs-4" for="place-filter>พื้นที่</label>';
        placeDiv.appendChild(placeSelect);
        this.filterContainer?.appendChild(placeDiv);

        // document.querySelector('#place-filter')?.addEventListener('input',e=>{
        //     showConcrete.applyFiltersAndDisplay(products,{PROVINCE_CODE: e.target.value});
        // });
    }

    
    async  getSelectedCheckboxValues(name) {
        const checkboxes = document.querySelectorAll(`input[name="${name}"]:checked`);
        
        return Array.from(checkboxes).map(checkbox => checkbox.value);
    }

    async getStrengthFilters(products) {
        
        const uniqueStrengths = [...new Set(products.map(product => product.prod_strenght))];
        
        const strengthFilters = uniqueStrengths.map(strength => {
            return { name: strength+' ksc', strength: strength };
        });
        
        return strengthFilters;
    }

    async getTruckFilters(products) {
        return [
            { id: 2, name: "รถโม่ใหญ่ 5 คิว" },
            { id: 1, name: "รถโม่เล็ก 2 คิว" }
        ];
    }

    async getTypeFilters(products) {
        // Create a set of unique pairs of concrete_type_name and concrete_type_id
        const typenames = [...new Set(products.map(product => JSON.stringify({
            concrete_type_name: product.concrete_type_name, 
            concrete_type_id: product.concrete_type_id
        })))];
    
        // Convert the set back to an array of objects
        const typeFilters = typenames.map(typename => JSON.parse(typename));
    
        return typeFilters;
    }
    
    async getPlaceFilters(products) {
        const placenames = [...new Set(products.map(product => JSON.stringify({
            PROVINCE_NAME:product.PROVINCE_NAME,
            PROVINCE_CODE:product.PROVINCE_CODE,
    })))];

    const placeFilters = placenames.map(placename=> JSON.parse(placename));
    return placeFilters;
    }
}

export { Filters };
